import api from '@/axios'

export default {
  findAll () {
    return api().get('system-transaction')
  },
  findOne (id) {
    return api().get(`system-transaction/${id}`)
  }
}
