export default class SystemTransaction {
  constructor () {
    this.setInitialValue()
  }

  setInitialValue () {
    this.systemTransaction = {
      transaction_type: '',
      source_type: '',
      source_id: '',
      direction: '',
      old_credit: '',
      transaction_value: '',
      system_wallet_id: '',
      systemWallet: { name: '' }
    }
  }

  fillData (data) {
    if (data) {
      this.systemTransaction.transaction_type = data.transaction_type ? data.transaction_type : ''
      this.systemTransaction.source_type = data.source_type ? data.source_type : ''
      this.systemTransaction.source_id = data.source_id ? data.source_id : ''
      this.systemTransaction.direction = data.direction ? data.direction : ''
      this.systemTransaction.old_credit = data.old_credit ? data.old_credit : ''
      this.systemTransaction.transaction_value = data.transaction_value ? data.transaction_value : ''
      this.systemTransaction.system_wallet_id = data.system_wallet_id ? data.system_wallet_id : ''
      this.systemTransaction.systemWallet.name = data.systemWallet.name ? data.systemWallet.name : ''
    } else {
      this.setInitialValue()
    }
  }
}
