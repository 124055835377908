<template>
  <div>
    <b-card>
      <ValidationObserver ref="systemTransaction" v-slot="{ handleSubmit }">
        <b-form class="form-top p-4" @submit.prevent="handleSubmit(formSubmit)">
        <b-row>
          <b-col md="6">
            <input-form v-model="systemTransaction.systemTransaction.transaction_value" :name="$t('systemTransactions.transactionValue')" :label="$t('systemTransactions.transactionValue')" :disabled="disableStatus" />
          </b-col>
          <b-col md="6">
            <input-form v-model="systemTransaction.systemTransaction.old_credit" :name="$t('systemTransactions.oldCredit')" :label="$t('systemTransactions.oldCredit')" :disabled="disableStatus" />
          </b-col>

          <b-col md="6">
            <input-form v-model="systemTransaction.systemTransaction.transaction_type" :name="$t('systemTransactions.transactionType')" :label="$t('systemTransactions.transactionType')" :disabled="disableStatus" />
          </b-col>
          <b-col md="6">
            <input-form v-model="systemTransaction.systemTransaction.direction" :name="$t('systemTransactions.direction')" :label="$t('systemTransactions.direction')" :disabled="disableStatus" />
          </b-col>

          <b-col md="6">
            <input-form v-model="systemTransaction.systemTransaction.source_type" :name="$t('systemTransactions.sourceType')" :label="$t('systemTransactions.sourceType')" :disabled="disableStatus" />
          </b-col>
          <b-col md="6">
            <input-form v-model="systemTransaction.systemTransaction.source_id" :name="$t('systemTransactions.sourceId')" :label="$t('systemTransactions.sourceId')" :disabled="disableStatus" />
          </b-col>

          <b-col md="12">
            <input-form v-model="systemTransaction.systemTransaction.systemWallet.name" :name="$t('systemWallets.item')" :label="$t('systemWallets.item')" :disabled="disableStatus" />
          </b-col>
        </b-row>
      </b-form>
      </ValidationObserver>
    </b-card>
  </div>
</template>
<script>
import { core } from '../../../../config/pluginInit'
import systemTransactionServices from '../services/systemTransactions'
import SystemTransaction from '../models/SystemTransaction'
import moduleItemMixin from '../../../../Mixins/moduleItemMixin'

export default {
  mixins: [moduleItemMixin],
  data () {
    return {
      systemTransaction: new SystemTransaction()
    }
  },
  mounted () {
    core.index()
  },
  created () {
    if (this.id) {
      systemTransactionServices.findOne(this.id).then(response => {
        this.systemTransaction.fillData(response.data.data)
        this.loading = false
      })
    } else {
      this.loading = false
    }
  }
}
</script>
